import React from 'react'
import Layout from '../components/Layout';
import { Link } from "react-router-dom";
import {ArrowLeftIcon} from '@heroicons/react/20/solid'

const Contact = () => {
  return (
    <Layout title='Contact'>
    <div className="w-full py-40 text-center">
            <h1 className="px-4 mx-auto font-mono text-2xl font-extrabold text-white lg:text-4xl">
            To get in touch please email:{" "}
                <span className="text-gradient from-purple-600 to-pink-600">
                <a href="mailto:contact@boostz.xyz">contact@boostz.xyz</a>
                </span>
            </h1>
            <Link
                to="/"
                className="inline-block mt-12 text-white hover:underline underline-offset-8"
            >
                <ArrowLeftIcon className="inline-block w-5 h-5 mr-2 -mt-1" />
                Go Home
            </Link>
        </div>
        </Layout>
  )
}

export default Contact;

