import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/Layout.js'
import Button from '../components/Button.js'

const About = () => {
  const [activeSection, setActiveSection] = useState(null);
  const whyBitcoinLightningRef = useRef(null);
  const whyNWCRef = useRef(null);

  const sections = [
    { name: 'whyBitcoinLightning', ref: whyBitcoinLightningRef },
    { name: 'whyNWC', ref: whyNWCRef },
  ];

  const handleScroll = () => {
    const currentSection = sections.find(section => {
      const element = section.ref.current;
      if (element) {
        const { offsetTop } = element;
        const screenPosition = window.scrollY + window.innerHeight / 2;
        return screenPosition > offsetTop && element.getBoundingClientRect().bottom >= 50;
      }
      return false;
    });

    setActiveSection(currentSection ? currentSection.name : null);
  };

  const handleClick = (jumpToSectionRef) =>{
    jumpToSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    const currentSection = sections.find(section =>{
      return section.ref === jumpToSectionRef;
    })
    setActiveSection(currentSection.name)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
      <Layout title="About Boostz" marginBottomClass={"mb-0"}>
          <section className="font-mono xl:px-12">
              <h1 className="text-white mx-auto mt-[50px] mb-[50px] text-[50px] tracking-wide max-md:text-[30px] max-md:mt-[30px] max-md:mb-[50px] md:max-lg:text-[30px]">
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                  Bitcoin Lightning via
                  </span>
                  <br />
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                   Nostr Wallet Connect
                  </span>
              </h1>
              <div className="flex flex-col border-t border-gray-700 md:flex-row">
                  <div className=" hidden w-0 lg:h-full lg:sticky lg:top-[90px] lg:w-1/4 lg:justify-left lg:items-center lg:flex lg:flex-1 lg:text-2xl lg:mt-4">
                      <ul>
                          <li
                              className={`${
                                  activeSection === "whyBitcoinLightning"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(whyBitcoinLightningRef)}
                          >
                              Why Bitcoin Lightning?
                          </li>
                          <li
                              className={`${
                                  activeSection === "whyNWC"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(whyNWCRef)}
                          >
                              Why NWC?
                          </li>
                      </ul>
                  </div>
                  <div className="w-full pl-1.5 lg:border-l lg:border-gray-700 lg:w-3/4 lg:pl-10">
                      <div id="whyBitcoinLightning" ref={whyBitcoinLightningRef} className="pt-16">
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                              Why Bitcoin Lightning?
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                          The Bitcoin Lightning network is a fast, secure, decentralized, censorship resistant global payment system.
                          Bitcoin Lightning and Boostz allow you to take control of your money and your banking needs.
</p>

                      </div>
                      <div
                          id="whyNWC"
                          ref={whyNWCRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                          Why Nostr Wallet Connect (NWC)
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              Nostr Wallet Connect allows you to use Boostz to control any NWC compatable Bitcoin Lightning wallet. 
                              Wallets control your bitcoin and lightning infrastructure and Boostz provides the mobile frontend to control your
                              money from anywhere.
                          </p>
                      </div>
                      <div className="pt-16 pb-24">
                          <Button
                              text="Contact Us"
                              link="/contact"
                              variant="gradient"
                              shadow={true}
                          />
                      </div>
                  </div>
              </div>
          </section>
      </Layout>
  );
}

export default About