import React from 'react'
import Layout from '../components/Layout.js'
import Button from '../components/Button.js'

const Home = () => {
  return (
      <Layout>
        <section>
          <div className="w-full text-center">
            <h1 className="text-white mx-auto mt-[70px] mb-[50px] text-[120px] tracking-[-6px] leading-[130px] font-extrabold max-md:text-[42px] max-md:leading-[60px] max-md:tracking-[-2px] max-md:mx-auto max-md:mt-[30px] max-md:mb-[50px] md:max-lg:text-[100px] md:max-lg:leading-[130px] md:max-lg:tracking-[-6px] md:max-lg:mx-auto md:max-lg:mt-[30px] md:max-lg:mb-[50px]">
              <span className='text-gradient from-gray-50 to-gray-300 pl-1.5 pr-1.5 mb-[-10px]'>Bitcoin Lightning via</span>
              <div>
                <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5 mb-[-10px]">Nostr Wallet Connect</span>
              </div>
              
            </h1>
            <p className="m-auto mt-0 text-[22px] font-normal text-gray-500 max-w-3xl leading-[34px] py-0 px-4 max-sm:text-[16px] max-sm:leading-[26px] sm:max-md:text-[18px] sm:max-md:leading-[28px] sm:max-md:max-w-xl">
              Boostz NWC Bitcoin Lightning app coming soon to iOS...
            </p>
            <div className="mt-[40px] flex justify-center items-center space-x-6">
              <Button text="Learn More" link="/about" variant="primary" />
              <Button text="Contact Us" link="/contact" variant="gradient" shadow={true} />
            </div>
          </div>
        </section>
      </Layout>
  )
}

export default Home